import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

// import { Container } from './styles';

interface IPath {
    name: string
    path: string
}

interface IProps {
    title: string
    path?: IPath[]
}

const PageTitle: React.FC<IProps> = ({ title, path = [] }) => {
    return (
        <div className="page-title-box">
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    {path.map((item, index) => (
                        <li className="breadcrumb-item" key={index}>
                            <Link to={item.path}>{item.name}</Link>
                        </li>
                    ))}
                    <li className="breadcrumb-item active">{title}</li>
                </ol>
            </div>
            <h4 className="page-title">{title}</h4>
        </div>
    )
}

export default PageTitle
