import React from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { EFrameSize, ICameraControl, IControllerType } from '../../types/general'

const sensibilityDefault = 3
const cameraControlDefault = 'ANALOGIC'

interface IProps {
    show: boolean
    setShow: React.Dispatch<React.SetStateAction<boolean>>
    typeInput: IControllerType
    sensibility: number
    setSensibility: React.Dispatch<React.SetStateAction<number>>
    sensibilityDirection: number
    setSensibilityDirection: React.Dispatch<React.SetStateAction<number>>
    cameraControl: ICameraControl
    setCameraControl: React.Dispatch<React.SetStateAction<ICameraControl>>
    gamepad: (Gamepad | null)[]

    JPEGQuality: number
    setJPEGQuality: React.Dispatch<React.SetStateAction<number>>
    frameSize: EFrameSize
    setFrameSize: React.Dispatch<React.SetStateAction<EFrameSize>>
    rotateDegrees: number
    setRotateDegrees: React.Dispatch<React.SetStateAction<number>>
}

const ModalControl: React.FC<IProps> = ({
    show,
    setShow,
    sensibility,
    setSensibility,
    typeInput,
    cameraControl,
    setCameraControl,
    setFrameSize,
    JPEGQuality,
    frameSize,
    setJPEGQuality,
    rotateDegrees,
    setRotateDegrees,
    setSensibilityDirection,
    sensibilityDirection,
}) => {
    const controllers = navigator.getGamepads().filter((gamepad) => gamepad !== null)
    const handlerDefault = () => {
        setSensibility(sensibilityDefault)
        setCameraControl(cameraControlDefault)
        setJPEGQuality(10)
        setFrameSize(EFrameSize['640x480'])
    }

    const saveConfigLocal = React.useCallback(() => {
        localStorage.setItem('sensibility', String(sensibility))
        localStorage.setItem('setSensibilityDirection', String(sensibilityDirection))
        localStorage.setItem('cameraControl', cameraControl)
    }, [sensibility, cameraControl, sensibilityDirection])

    React.useEffect(() => {
        saveConfigLocal()
    }, [sensibility, cameraControl, saveConfigLocal, sensibilityDirection])

    const renderAlert = () => (
        <Alert variant="warning" className="p-3 mt-3">
            <Alert.Heading>Atenção!</Alert.Heading>
            <p>
                Foram detectados {controllers.length} controles, porém, apenas o primeiro será utilizado. <br />
            </p>
            <span>Para utilizar outro controle, desconecte o atual e conecte o novo.</span>
            <h5 className="mt-2">Controles Detectados</h5>
            <ul className="mt-2">
                {controllers.map((controller, index) => (
                    <li key={index}>{controller?.id}</li>
                ))}
            </ul>
        </Alert>
    )

    return (
        <Modal dialogClassName="modal-sm modal-right" show={show} onHide={() => setShow(false)}>
            <div className="modal-header border-0">
                <button type="button" className="btn-close" onClick={() => setShow(false)} />
            </div>

            <div className="modal-body">
                <div>
                    <h5>Configurações</h5>
                    <p className="text-muted m-0">Ajuste a sensibilidade do controle</p>
                    <p className="text-muted">
                        Tipo de Controle: <strong>{typeInput}</strong>
                    </p>
                    <hr className="my-4" />
                </div>

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Compressão JPEG</Form.Label>
                        <Form.Range min={10} max={63} step={1} defaultValue={JPEGQuality} onChange={(e) => setJPEGQuality(Number(e.target.value))} />
                        <Form.Text className="text-muted">Qualidade: {JPEGQuality}</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Resolução</Form.Label>
                        <Form.Range min={0} max={11} step={1} defaultValue={frameSize} onChange={(e) => setFrameSize(Number(e.target.value))} />
                        <Form.Text className="text-muted">Resolução: {EFrameSize[frameSize]}</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Sensibilidade Aceleração e Frenagem</Form.Label>
                        <Form.Range min={0} max={5} step={0.1} defaultValue={sensibility} onChange={(e) => setSensibility(Number(e.target.value))} />
                        <Form.Text className="text-muted">Sensibilidade: {sensibility}</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Sensibilidade Direção</Form.Label>
                        <Form.Range min={0} max={5} step={0.1} defaultValue={sensibilityDirection} onChange={(e) => setSensibilityDirection(Number(e.target.value))} />
                        <Form.Text className="text-muted">Sensibilidade: {sensibilityDirection}</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Usar setas ao invés de analógico para a câmera</Form.Label>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Ativar"
                            onChange={(e) => setCameraControl(e.target.checked ? 'ARROW' : 'ANALOGIC')}
                            checked={cameraControl === 'ARROW'}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Rotacionar imagem</Form.Label>
                        <Form.Range min={0} max={360} step={90} defaultValue={rotateDegrees} onChange={(e) => setRotateDegrees(Number(e.target.value))} />
                        <Form.Text className="text-muted">Graus Atual: {rotateDegrees}</Form.Text>
                    </Form.Group>

                    <div className="d-flex align-items-right justify-content-end">
                        <Button variant="outline-dark" type="reset" size="sm" onClick={handlerDefault}>
                            Padrão
                        </Button>
                    </div>
                </Form>
                {controllers.length > 1 && renderAlert()}
            </div>
        </Modal>
    )
}

export default ModalControl
