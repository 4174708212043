import { ProfileCircle } from 'iconsax-react'
import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ISessionUser } from '../../types/general'
import { UserService } from '../../services/api/UserService'

type Inputs = {
    email: string
    name: string
}

interface IProps {
    sessionUser: ISessionUser
    setSessionUser: (user: ISessionUser) => void
}

const PersonalInfo: React.FC<IProps> = ({ sessionUser, setSessionUser }) => {
    const { setValue, register, handleSubmit } = useForm<Inputs>()

    React.useEffect(() => {
        setValue('email', sessionUser!.email)
        setValue('name', sessionUser!.name)
    }, [sessionUser, setValue])

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        UserService.updateName(data.name)
            .then((response) => {
                if (response.status === 200) {
                    setSessionUser({ ...sessionUser!, name: data.name })
                    toast.success('Nome atualizado com sucesso!')
                }
            })
            .catch((error) => {
                toast.error('Erro ao atualizar nome!')
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="mb-4 text-uppercase bg-light p-2 d-flex justify-content-start align-items-center">
                <ProfileCircle className="me-2" size="20" /> Informações Pessoais
            </h5>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Nome
                        </label>
                        <input type="text" className="form-control" id="name" placeholder="Seu Nome" {...register('name')} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="useremail" className="form-label">
                            Email
                        </label>
                        <input type="email" disabled className="form-control" id="useremail" placeholder="Seu email" {...register('email')} />
                    </div>
                </div>
            </div>
            <div className="text-end">
                <button type="submit" className="btn btn-success mt-2">
                    Salvar
                </button>
            </div>
        </form>
    )
}

export default PersonalInfo
