import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

interface IProps {
    handlerUpdate: (emotion: string) => void
    textEye: string
    setTextEye: (text: string) => void
}

const Persona: React.FC<IProps> = ({ handlerUpdate, textEye, setTextEye }) => {
    return (
        <div className="card card-body">
            <div className="card-title">
                <strong>Personalidade</strong>
            </div>
            <div>
                <ButtonGroup className="w-100 mb-1">
                    <Button variant="outline-primary p-1" style={{ width: '33%' }} data-emotion="neutral" onClick={(e) => handlerUpdate('neutral')}>
                        Neutro
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="blink" onClick={(e) => handlerUpdate('blink')}>
                        Piscando
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="angry" onClick={(e) => handlerUpdate('angry')}>
                        Bravo
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="w-100 mb-1">
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="evil" onClick={(e) => handlerUpdate('evil')}>
                        Malvado
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="evil2" onClick={(e) => handlerUpdate('evil2')}>
                        Malvado
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="squint" onClick={(e) => handlerUpdate('squint')}>
                        Tédio
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="w-100 mb-1">
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="core" onClick={(e) => handlerUpdate('core')}>
                        Coração
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="sleep" onClick={(e) => handlerUpdate('sleep')}>
                        Dorme
                    </Button>
                    <Button variant="outline-primary p-2" style={{ width: '33%' }} data-emotion="sad" onClick={(e) => handlerUpdate('sad')}>
                        Triste
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="w-100 mb-1">
                    <Button variant="outline-primary p-2" data-emotion="dead" onClick={(e) => handlerUpdate('dead')}>
                        Morto
                    </Button>
                </ButtonGroup>
                <hr />

                <form className="w-100">
                    <div className="input-group mb-1">
                        <input type="text" className="form-control" placeholder="Texto para exibir" value={textEye} onChange={(e) => setTextEye(e.target.value)} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="reset">
                                Limpar
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center ">
                        <input type="button" className="btn btn-outline-secondary w-100" defaultValue="Enviar" onClick={(e) => handlerUpdate(textEye)} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Persona
