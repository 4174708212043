import React from 'react'
import { AuthService } from '../services/api/AuthService'
import { API, socket } from '../services'
import { ISessionState, ISessionUser } from '../types/general'

interface ISessionContext {
    saveSession: (token: string) => void
    setSessionUser: (user: ISessionUser) => void
    removeSession: () => void
    getSession: () => string | null
    state: ISessionState
    sessionUser?: ISessionUser
}

export const SessionContext = React.createContext({} as ISessionContext)

interface IProps {
    children: React.ReactNode
}

const SessionProvider: React.FC<IProps> = ({ children }) => {
    const [state, setState] = React.useState<ISessionState>('LOADING')
    const [sessionUser, setSessionUser] = React.useState<ISessionUser | undefined>(undefined)

    React.useEffect(() => {
        const token = localStorage.getItem('token')
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`
        AuthService.check()
            .then((res) => {
                if (res.status === 200) {
                    setSessionUser(res.data.data.user)
                    setState('AUTHENTICATED')
                    socket.io.opts.query = { token }
                    socket.connect()
                }
            })
            .catch(() => {
                setState('UNAUTHENTICATED')
            })
    }, [])

    const saveSession = (token: string) => {
        localStorage.setItem('token', token)
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`
        setState('AUTHENTICATED')
        socket.io.opts.query = { token }
        socket.connect()
    }

    const removeSession = () => {
        localStorage.removeItem('token')
        setState('UNAUTHENTICATED')
        socket.disconnect()
    }

    const getSession = () => {
        return localStorage.getItem('token')
    }

    return <SessionContext.Provider value={{ saveSession, setSessionUser, removeSession, getSession, state, sessionUser }}>{children}</SessionContext.Provider>
}

export default SessionProvider
