import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import SessionProvider from './context/SessionContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <SessionProvider>
            <App />
        </SessionProvider>
    </React.StrictMode>
)
