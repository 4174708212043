import React from 'react'
import { ITelemetry } from '../../types/general'
import { BatteryCharging, Cpu, Speedometer, WifiSquare } from 'iconsax-react'
import { ProgressBar } from 'react-bootstrap'

// import { Container } from './styles';
interface IProps {
    telemetry: ITelemetry[]
}

const CardTelemetry: React.FC<IProps> = ({ telemetry }) => {
    return (
        <div className="card card-body">
            <div className="card-title">
                <strong>Telemetria</strong>
            </div>
            {telemetry.map((item, index) => (
                <div className="mb-1" key={index}>
                    <span>
                        <strong>{item.type}</strong>
                    </span>
                    <div className="d-flex flex-row align-items-center justify-content-between" key={index}>
                        <span className="d-flex flex-row align-items-center" style={{ width: '150px' }}>
                            <Speedometer size="16" />
                            <span className="ms-1">{item.latency} ms</span>
                        </span>
                        <span className="d-flex flex-row align-items-center w-100 mx-1">
                            <WifiSquare size="16" />
                            <ProgressBar className="ms-1" label={item.signal + ' dBm'} style={{ width: '100%', height: '14px' }} now={item.signal_percent} />
                        </span>
                        <span className="d-flex flex-row align-items-center justify-content-end" style={{ width: '135px' }}>
                            <Cpu size="16" />
                            <span className="ms-1">{item.temperature} °C</span>
                        </span>
                        <span className="d-flex flex-row align-items-center justify-content-end" style={{ width: '130px' }}>
                            <BatteryCharging size="16" />
                            <span className="ms-1">{item.battery} V</span>
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default React.memo(CardTelemetry)
