import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Indoor from './stacks/Indoor'
import { SessionContext } from './context/SessionContext'
import Outdoor from './stacks/Outdoor'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import LoadPage from './components/LoadPage'

function App() {
    const { state } = React.useContext(SessionContext)
    return (
        <>
            <div className="App">
                <Router>
                    {state === 'AUTHENTICATED' && <Indoor />}
                    {state === 'UNAUTHENTICATED' && <Outdoor />}
                    {state === 'LOADING' && <LoadPage />}
                </Router>
            </div>
            <ToastContainer position="top-center" />
        </>
    )
}

export default App
