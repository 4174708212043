import React from 'react'

// import { Container } from './styles';

interface IProps {
    inputType: string
    iconType: React.ReactElement
}

const InputControl: React.FC<IProps> = ({ inputType, iconType }) => {
    return (
        <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
                <div className="avatar-sm rounded">
                    <span className="avatar-title bg-transparent border border-secundary text-danger rounded">{iconType}</span>
                </div>
            </div>
            <div className="flex-grow-1 ms-2">
                <h4 className="h4 my-0 fw-semibold text-reset">{inputType} </h4>
            </div>
        </div>
    )
}

export default InputControl
