import React from 'react'
import { Eye, EyeSlash } from 'iconsax-react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import Logo from '../../assets/images/logo-light.png'
import './styles.scss'

import { AuthService } from '../../services/api/AuthService'
import { SessionContext } from '../../context/SessionContext'
import { Button } from 'react-bootstrap'

type Inputs = {
    email: string
    password: string
}

const Login: React.FC = () => {
    const [passwordDisplay, setPasswordDisplay] = React.useState(false)
    const { saveSession, setSessionUser } = React.useContext(SessionContext)
    const history = useHistory()

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<Inputs>()

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        AuthService.login(data.email, data.password)
            .then((res) => {
                if (res.status === 200) {
                    saveSession(res.data.data.token)
                }
            })
            .then(() => {
                return AuthService.check()
            })
            .then((res) => {
                if (res.status === 200) {
                    setSessionUser(res.data.data.user)
                    history.replace('/home')
                }
            })
            .catch((err) => {
                if (err.response) {
                    setError('email', { message: err.response.data.message })
                    setError('password', { message: err.response.data.message })
                } else {
                    toast.error('Erro ao realizar login')
                }
            })
    }

    return (
        <div>
            <div className="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 800">
                    <g fillOpacity="0.22">
                        <circle style={{ fill: 'rgba(var(--ct-primary-rgb), 0.1)' }} cx={400} cy={400} r={600} />
                        <circle style={{ fill: 'rgba(var(--ct-primary-rgb), 0.2)' }} cx={400} cy={400} r={500} />
                        <circle style={{ fill: 'rgba(var(--ct-primary-rgb), 0.3)' }} cx={400} cy={400} r={300} />
                        <circle style={{ fill: 'rgba(var(--ct-primary-rgb), 0.4)' }} cx={400} cy={400} r={200} />
                        <circle style={{ fill: 'rgba(var(--ct-primary-rgb), 0.5)' }} cx={400} cy={400} r={100} />
                    </g>
                </svg>
            </div>
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-lg-5">
                            <div className="card">
                                <div className="card-header text-center bg-primary">
                                    <span>
                                        <img src={Logo} alt="logo" height={80} />
                                    </span>
                                </div>
                                <div className="card-body p-4">
                                    <div className="text-left">
                                        <h4 className="text-dark-50 pb-0 fw-bold">Login</h4>
                                        <p className="text-muted mb-4">Entre com seu email e senha para acessar o sistema.</p>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-3">
                                            <label htmlFor="emailaddress" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                className={'form-control ' + (errors.email ? 'is-invalid' : '')}
                                                type="email"
                                                id="emailaddress"
                                                required
                                                placeholder="Seu email"
                                                {...register('email', { required: true })}
                                            />
                                            {errors.email && <span className="text-danger my-2">{errors.email.message}</span>}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">
                                                Senha
                                            </label>
                                            <div className="input-group input-group-merge">
                                                <input
                                                    type={passwordDisplay ? 'text' : 'password'}
                                                    id="password"
                                                    className={'form-control ' + (errors.password ? 'is-invalid' : '')}
                                                    placeholder="Sua senha"
                                                    {...register('password', { required: true })}
                                                />
                                                <div className="input-group-text" onClick={() => setPasswordDisplay(!passwordDisplay)}>
                                                    {passwordDisplay ? <EyeSlash size="16" /> : <Eye size="16" />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 mb-0 text-center">
                                            <Button type="submit" variant="primary" className="w-100">
                                                Entrar
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 text-center">
                                    <p className="text-muted">
                                        Não tem conta ?{' '}
                                        <a href="pages-register.html" className="text-muted ms-1">
                                            <b>Registre-se</b>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
