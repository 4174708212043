import React from 'react'
import { toast } from 'react-toastify'
import PageTitle from '../../components/PageTitle'
import { Card, CardBody } from 'react-bootstrap'

import Robots from './Cards'
import './styles.scss'
import { socket } from '../../services'
import { useHistory } from 'react-router-dom'

import VoidImg from '../../assets/images/the_moon.svg'
import ModalView from './ModalView'

interface IRobot {
    allocated: boolean
    name: string
    serialNumber: string
    statusCam: 'ONLINE' | 'OFFLINE'
    statusEsp32: 'ONLINE' | 'OFFLINE'
}

// ResponseCode
// 0: OK
// 1: Your user busy
// 2: Robot is busy, view available robots
// 3: Robot is busy, view not  available robots

const Home: React.FC = () => {
    const history = useHistory()
    const [robotEvents, setRobotFooEvents] = React.useState<Array<IRobot>>([])
    const [allocateSerialNumber, setAllocateSerialNumber] = React.useState<string | null>(null)
    const [modalAllocated, setModalAllocated] = React.useState(false)

    React.useEffect(() => {
        socket.on('robots', setRobotFooEvents)
        socket.emit('get-robots', setRobotFooEvents)

        return () => {
            socket.off('robots', setRobotFooEvents)
        }
    }, [])

    const handlerAllocate = (serialNumber: string) => {
        socket.emit('allocate', { serialNumber, mode: 'CONTROL' }, (res: any) => {
            if (res.code === 0) {
                toast.success('Robô alocado com sucesso!')
                history.push(`/control/${serialNumber}`)
            } else if (res.code === 1) {
                toast.error('Você já está usando um robô!')
            } else if (res.code === 2) {
                setAllocateSerialNumber(serialNumber)
                setModalAllocated(true)
            } else if (res.code === 3) {
                toast.error('Robô não disponível!')
            } else {
                toast.error('Erro ao alocar o robô!')
            }
        })
    }

    const handlerAllocateView = () => {
        console.log(allocateSerialNumber)
        socket.emit('allocate', { serialNumber: allocateSerialNumber, mode: 'GUEST' }, (res: any) => {
            if (res.code === 0) {
                toast.success('Robô alocado com sucesso!')
                history.push(`/view/${allocateSerialNumber}`)
            } else if (res.code === 1) {
                toast.error('Você já está usando um robô!')
            } else if (res.code === 2) {
                toast.error('Robô não disponível!')
            } else {
                toast.error('Erro ao alocar o robô!')
            }
        })
    }

    return (
        <>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <PageTitle title="Lista de Robôs" />
                        </div>
                    </div>
                    <div className="row">
                        {robotEvents.length === 0 ? (
                            <div className="col-12 d-flex justify-content-center">
                                <Card style={{}} className="p-3">
                                    <CardBody>
                                        <div className="d-flex justify-content-center ">
                                            <h4>Nenhum robô disponível</h4>
                                        </div>
                                        <div className="d-flex justify-content-center ">
                                            <p className="text-muted">Por favor, tente novamente mais tarde</p>
                                        </div>
                                        <div className="d-flex justify-content-center mt-3">
                                            <img src={VoidImg} alt="void" height={250} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ) : (
                            <>
                                {robotEvents.map((event, index: number) => (
                                    <Robots key={index} {...event} onClick={() => handlerAllocate(event.serialNumber)} />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ModalView show={modalAllocated} handleClose={() => setModalAllocated(false)} onConfirm={() => handlerAllocateView()} />
        </>
    )
}

export default Home
