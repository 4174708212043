import React, { useEffect, useRef } from 'react'

const EyesCanvas = () => {
    const canvasRef = useRef(null)
    let canvas: HTMLCanvasElement

    useEffect(() => {
        canvas = canvasRef.current as any
        const ctx = canvas.getContext('2d')

        // Criar uma nova instância de Image
        var imgEyes = new Image()

        // Especificar o caminho da imagem
        imgEyes.src = '/eye-robot.png'

        if (!ctx) return

        const lookat = { x: 150, y: 75 }
        let toggleBlink = true
        const eye = {
            radius: 50,
            iris: 30,
            limMin: -0.1,
            limMax: 1.1,
            blinkDuration: 750, // Duration of the blink in milliseconds
            lastBlinkTime: 0, // Time of the last blink
            frameBlinkTime: 4000, // Time between blinks in milliseconds
            frameAnimation: 0, // Animation frame id
            frameStage: 0, // Stage of the blink animation
        }

        const drawEyes = () => {
            let { x, y } = lookat

            x /= canvas.width
            y /= canvas.height

            x = x < eye.limMin ? eye.limMin : x > eye.limMax ? eye.limMax : x
            y = y < eye.limMin ? eye.limMin : y > eye.limMax ? eye.limMax : y

            x -= 0.5
            y -= 0.5

            const range = (eye.radius - eye.iris) * 2

            x *= range
            y *= range

            // Set line properties for the asterisk
            ctx.lineWidth = 2
            ctx.strokeStyle = '#000'

            // Definir o preenchimento do canvas (background)
            ctx.fillStyle = '#0C1741' // Cor de fundo, você pode usar qualquer cor ou padrão CSS válido

            // Preencher todo o canvas com a cor de fundo
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            // Evento de carga da imagem
            ctx.drawImage(imgEyes, 60 + x, 25 + y)

            ctx.drawImage(imgEyes, 160 + x, 25 + y)

            // animation blink eyes
            const now = Date.now()
            const delta = now - eye.lastBlinkTime
            if (delta > eye.frameBlinkTime) {
                // blink
                ctx.fillRect(0, 0 + eye.frameAnimation - 150, canvas.width, canvas.height)

                switch (eye.frameStage) {
                    case 0:
                        eye.frameAnimation = eye.frameAnimation + eye.blinkDuration / 50
                        if (eye.frameAnimation > 150) {
                            eye.frameStage = 1
                        }
                        break
                    case 1:
                        eye.frameAnimation = eye.frameAnimation - eye.blinkDuration / 50
                        if (eye.frameAnimation <= 0) {
                            eye.frameAnimation = 0
                            eye.lastBlinkTime = now
                            eye.frameStage = 0
                        }
                        break
                }

                // if (toggleBlink) {
                //     eye.frameAnimation += 1
                // } else {
                //     eye.frameAnimation -= 1
                // }

                // if (eye.frameAnimation > 50) {
                //     toggleBlink = !toggleBlink
                // } else if (eye.frameAnimation <= 0) {
                //     eye.frameAnimation = 0
                //     eye.lastBlinkTime = now
                //     toggleBlink = !toggleBlink
                // }
            }

            ctx.restore()
        }

        const handleMouseMove = (e) => {
            const bounds = canvas.getBoundingClientRect()
            lookat.x = e.pageX - bounds.left
            lookat.y = e.pageY - bounds.top

            // drawEyes()
        }

        canvas.addEventListener('mousemove', handleMouseMove)

        // Initial draw
        // drawEyes()

        const loopAnimation = () => {
            drawEyes()
        }

        // Start animation loop
        const val = setInterval(loopAnimation, 1000 / 60)

        // Cleanup event listener on component unmount
        return () => {
            clearInterval(val)
            canvas.removeEventListener('mousemove', handleMouseMove)
        }
    }, [])

    return <canvas ref={canvasRef} id="canvas" width="300" height="150"></canvas>
}

export default EyesCanvas
