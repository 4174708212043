import React from 'react'
import { Refresh } from 'iconsax-react'
import Avatar from 'boring-avatars'
import { MatchService } from '../../services/api/MatchService'
import { toast } from 'react-toastify'
import { socket } from '../../services'
import { Button } from 'react-bootstrap'

const AllocatedAdmin: React.FC = () => {
    const [list, setList] = React.useState<any[]>([])

    const handlerDesalocar = (id: string) => {
        MatchService.destroy(id)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Desalocado com sucesso')
                }
            })
            .catch((error) => {
                toast.error('Erro ao desalocar')
            })
    }

    const handlerRefresh = () => {
        MatchService.refresh()
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Atualizado com sucesso')
                }
            })
            .catch((error) => {
                toast.error('Erro ao atualizar')
            })
    }
    React.useEffect(() => {
        socket.on('match', (data) => {
            console.log(data)
            setList(data)
        })

        MatchService.getAll()
            .then((response) => {
                setList(response.data.data)
            })
            .catch((error) => {
                console.log(error)
            })

        return () => {
            socket.off('match')
        }
    }, [])
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <button className="btn btn-primary ms-2" onClick={() => handlerRefresh()}>
                                        <Refresh size="15" />
                                    </button>
                                </div>
                            </div>
                            <h4 className="page-title">Lista de alocações</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-0">
                            <div className="card-body">
                                {list.map((item) => (
                                    <div className="row justify-content-sm-between">
                                        <div className="col-1">
                                            <Avatar size={40} name="Maria" variant="beam" />
                                        </div>
                                        <div className="col-5 d-flex flex-column">
                                            <span>User: {item.userId}</span>
                                            <span>Robot: {item.robotId}</span>
                                        </div>
                                        <div className="col-3 d-flex flex-column">
                                            <span>Mode: {item.mode}</span>
                                            <span>Serial Number: {item.robotSerialNumber}</span>
                                        </div>
                                        <div className="col-3 d-flex justify-content-end align-items-center">
                                            <Button variant="outline-danger" onClick={() => handlerDesalocar(item.userId)}>
                                                Desalocar
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllocatedAdmin
