import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { Game, Keyboard, Setting4 } from 'iconsax-react'

import { EFrameSize, ICameraControl, IControllerType, IDirection, ITelemetry } from '../../types/general'
import { socket } from '../../services'
import PageTitle from '../../components/PageTitle'
import ModalControl from './ModalConfig'
import CardTelemetry from '../../components/CardTelemetry'
import AudioRecorder from './AudioStream'
import InputControl from '../../components/InputControl'
import MapGPS from './Map'
import Personality from './Persona'
import Remote from './Remote'
import CamControl from './CamControl'
import GamePadMobile from '../../components/GamePadMobile'

const audioContext = new window.AudioContext()

const Control: React.FC = () => {
    // Load config from localStorage
    const sensibilityLocal = localStorage.getItem('sensibility') || '3'
    const sensibilityDirectionLocal = localStorage.getItem('sensibilityDirection') || '1'
    const cameraControlLocal = localStorage.getItem('cameraControl') || 'ANALOGIC'
    const [gamepad, setGamepad] = React.useState<any[]>([])

    const imageRef = React.useRef<any>()

    const [command, setCommand] = React.useState<string>('stop')
    const [imageLength, setImageLength] = React.useState<number>(0)
    const [rotateDeg, setRotateDeg] = React.useState<number>(0)

    const [telemetry, setTelemetry] = React.useState<ITelemetry[]>([])

    const [inputType, setInputType] = React.useState<IControllerType>(gamepad.length > 0 ? 'GAMEPAD' : 'KEYBOARD')
    const [sensibility, setSensibility] = React.useState<number>(Number(sensibilityLocal))
    const [sensibilityDirection, setSensibilityDirection] = React.useState<number>(Number(sensibilityDirectionLocal))
    const [cameraControl, setCameraControl] = React.useState<ICameraControl>(cameraControlLocal as ICameraControl)
    const [JPEGQuality, setJPEGQuality] = React.useState<number>(10)
    const [frameSize, setFrameSize] = React.useState<EFrameSize>(EFrameSize['640x480'])

    const [modalConfig, setModalConfig] = React.useState<boolean>(false)

    const [tilt, setTilt] = React.useState<number>(90)
    const [pan, setPan] = React.useState<number>(90)

    const [textEye, setTextEye] = React.useState<string>('')

    const history = useHistory()
    const params: any = useParams()

    const id = params.id

    const handlerUpdate = (emotion: string) => {
        socket.emit('control', `${emotion}`)
    }

    const handleKeyDown = (e: any) => {
        let payload: string = ''
        if (e.key === 'w') {
            payload = 'up'
        } else if (e.key === 's') {
            payload = 'down'
        } else if (e.key === 'd') {
            payload = 'left'
        } else if (e.key === 'a') {
            payload = 'right'
        }
        if (!payload) return
        socket.emit('control', payload)
        setCommand(payload)
    }

    const toggleLight = () => {
        socket.emit('control', 'led')
    }

    const toggleFlash = () => {
        socket.emit('control', 'flash')
    }

    const handlerKeyUp = (e: any) => {
        socket.emit('control', 'stop')
        setCommand('stop')
    }

    const onBeforeUnload = (e: any) => {
        socket.emit('control', 'stop')
        socket.emit('deallocate')
        console.log('desconectado')
        toast.warn('Você foi desalocado do robo!')
    }

    const handleConnect = (e: any) => {
        const gamepadInstance = navigator.getGamepads().filter((item) => item !== null)

        if (gamepadInstance.length > 1) {
            toast.warn('Mais de um controle conectado, apenas o primeiro será utilizado!')
        } else {
            toast.success('Controle conectado!')
        }
        setInputType('GAMEPAD')
        setGamepad(gamepadInstance)
    }

    const handlerDisconnectGamepad = (e: any) => {
        toast.warn('Controle desconectado!')
        setInputType('KEYBOARD')
        // setGamepad([])
    }

    const resetTiltPan = () => {
        setTilt(90)
        setPan(90)
        const command = `servo#90#90`
        setCommand(command)
        socket.emit('control', command)
    }

    const sendTiltPan = (tilt: number, pan: number) => {
        setTilt(tilt)
        setPan(pan)
        // invert tilt
        let tiltInv = 180 - tilt
        // invert pan
        let panInv = 180 - pan

        const command = `servo#${tiltInv}#${panInv}`
        setCommand(command)
        socket.emit('control', command)
    }

    const handlerDirectionMobile = (direction: IDirection) => {
        const speed = 255;
        let command = `motor#0#0`
        
        if (direction === 'up') {
            command = `motor#0#${speed}`
        } else if (direction === 'down') {
            command = `motor#0#-${speed}`
        } else if (direction === 'left') {
            command = `motor#-255#${speed}`
        } else if (direction === 'right') {
            command = `motor#255#${speed}`
        } else if (direction === 'stop') {
            command = `motor#0#0`
        }

        setCommand(command)
        console.log(command, direction)
        socket.emit('control', command)
    }

    React.useEffect(() => {
        socket.on('live', (message) => {
            var bytes = new Uint8Array(message)
            var binary = ''
            var len = bytes.byteLength
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i])
            }
            setImageLength(len)
            if (imageRef.current) {
                imageRef.current.src = 'data:image/jpg;base64,' + window.btoa(binary)
            }
        })

        socket.on('telemetry', (message: ITelemetry[]) => {
            // Signal RSSI, -30 dBm to - 90 dBm, convert to percent
            message = message.map((item) => {
                item.signal_percent = Math.round(((item.signal + 90) * 100) / 60)
                return item
            })
            setTelemetry(message)
        })

        socket.on('audio', (message: any) => {
            // console.log('audio')
            const bytes = new Uint8Array(message).slice(0, -1)

            audioContext.decodeAudioData(
                bytes.buffer,
                function (buffer) {
                    const source = audioContext.createBufferSource()
                    source.buffer = buffer

                    // Connect the source to the destination (e.g., speakers)
                    source.connect(audioContext.destination)

                    // Start playing the audio
                    source.start(0)
                },
                function (error) {
                    console.error('Error decoding audio data', error)
                }
            )
        })

        return () => {
            socket.off('live')
            socket.off('telemetry')
            socket.off('audio')
        }
    }, [])

    React.useEffect(() => {
        // const tempGamepad = navigator.getGamepads().filter((item) => item !== null)
        // setGamepad(tempGamepad)
        // setInputType('GAMEPAD')
    }, [inputType])

    React.useEffect(() => {
        socket.emit('control', `frame#${frameSize}#${JPEGQuality}`)
    }, [frameSize, JPEGQuality])

    // Loop Gamepad read state
    React.useEffect(() => {
        let lastCommandServo = 'servo#90#90'
        let lastCommandMotor = 'motor#0#0'
        let rafId: NodeJS.Timer

        const updateGamepad = () => {
            if (gamepad.length > 0) {
                let gamepadData = navigator.getGamepads().filter((item) => item !== null)

                if (!gamepadData[0]) return

                const axes = gamepadData[0].axes
                const buttons = gamepadData[0].buttons

                const direction = sensibilityDirection * axes[0] * -1 // -1 to 1
                const speed = sensibility * buttons[7].value
                const reverse = sensibility * buttons[6].value

                let commandServo = `servo#90#90`

                if (cameraControl === 'ANALOGIC') {
                    const tilt = axes[2] * -1
                    const pan = axes[3]
                    // converte -1 to 1 to 0 to 180, 90 is center
                    let tiltSend = Math.round((tilt + 1) * 90)
                    let panSend = Math.round((pan + 1) * 90)

                    commandServo = `servo#${tiltSend}#${panSend}`
                } else if (cameraControl === 'ARROW') {
                    // Center servo
                    let tilt = 90
                    let pan = 90

                    // 12 = left, 13 = right, 14 = up, 15 = down
                    if (buttons[12].value === 1) {
                        pan = 0
                    } else if (buttons[13].value === 1) {
                        pan = 180
                    }

                    if (buttons[14].value === 1) {
                        tilt = 180
                    } else if (buttons[15].value === 1) {
                        tilt = 0
                    }

                    commandServo = `servo#${tilt}#${pan}`
                }

                if (commandServo !== lastCommandServo) {
                    console.log(commandServo)
                    setCommand(commandServo)
                    // send to server
                    socket.emit('control', commandServo)
                    lastCommandServo = commandServo
                }

                // converte -1 to 1 to -255 to 255
                let dirSend = Math.ceil(direction * 255)
                // converte 0 to 1 to 0 to 255 and sum speed and reverte in one value
                let speedSend = Math.round((speed - reverse) * 255)

                const commandMotor = `motor#${dirSend}#${speedSend}`

                if (commandMotor !== lastCommandMotor) {
                    console.log(commandMotor)
                    setCommand(commandMotor)
                    // send to server
                    socket.emit('control', commandMotor)
                    lastCommandMotor = commandMotor
                }
            }
        }

        // Loop
        rafId = setInterval(updateGamepad, 100)

        // const temp = navigator.getGamepads().filter((item) => item !== null)
        // // Start loop
        // if (temp.length > 0) {
        //     rafId = requestAnimationFrame(updateGamepad)
        // }

        return () => {
            clearInterval(rafId)
        }
    }, [gamepad, sensibility, cameraControl, sensibilityDirection])

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handlerKeyUp)
        window.addEventListener('beforeunload', onBeforeUnload)
        window.addEventListener('gamepadconnected', handleConnect)
        window.addEventListener('gamepaddisconnected', handlerDisconnectGamepad)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handlerKeyUp)
            window.removeEventListener('beforeunload', onBeforeUnload)
            window.removeEventListener('gamepadconnected', handleConnect)
            window.removeEventListener('gamepaddisconnected', handlerDisconnectGamepad)
        }
    }, [])

    React.useEffect(() => {
        const unregister = history.listen(onBeforeUnload)
        return () => {
            unregister()
        }
    }, [history])

    return (
        <>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <PageTitle
                                title={'Controle - ' + id}
                                path={[
                                    {
                                        name: 'Home',
                                        path: '/',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-12 col-md-3">
                            {/* <AudioRecorder /> */}

                            <Personality handlerUpdate={handlerUpdate} textEye={textEye} setTextEye={setTextEye} />
                            {/* <MapGPS /> */}
                        </div>
                        <div className="col-12 col-md-6">
                            {/*  <AudioRecorder /> */}

                            <div className="card card-body p-0 d-flex" style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                <img className="w-100 img-fluid rounded" ref={imageRef} alt="Live" style={{ transform: `rotate(${rotateDeg}deg)` }} />
                            </div>
                        </div>

                        {inputType === 'KEYBOARD' && (
                            <div className="col-12 col-md-3 d-md-none d-block">
                                <GamePadMobile sendDirection={(d) => handlerDirectionMobile(d)} />
                            </div>
                        )}

                        <div className="col-md-3">
                            <CardTelemetry telemetry={telemetry} />
                            <Remote
                                command={command}
                                imageLength={imageLength}
                                toggleFlash={toggleFlash}
                                toggleLight={toggleLight}
                                setModalConfig={setModalConfig}
                                inputType={inputType}
                            />

                            {/* Servo control without gamepad */}
                            {inputType === 'KEYBOARD' && <CamControl tilt={tilt} pan={pan} resetTiltPan={resetTiltPan} sendTiltPan={sendTiltPan} />}
                        </div>
                    </div>
                </div>
            </div>
            <ModalControl
                JPEGQuality={JPEGQuality}
                setJPEGQuality={setJPEGQuality}
                frameSize={frameSize}
                setFrameSize={setFrameSize}
                show={modalConfig}
                setShow={setModalConfig}
                sensibility={sensibility}
                setSensibility={setSensibility}
                cameraControl={cameraControl}
                setCameraControl={setCameraControl}
                typeInput={inputType}
                gamepad={navigator.getGamepads()}
                rotateDegrees={rotateDeg}
                setRotateDegrees={setRotateDeg}
                sensibilityDirection={sensibilityDirection}
                setSensibilityDirection={setSensibilityDirection}
            />
        </>
    )
}

export default Control
