import axios from 'axios'
import { io } from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_API_URL as string

export const API = axios.create({
    baseURL: URL,
})

export const socket = io(URL, {
    transports: ['websocket'],
    upgrade: false,
    autoConnect: false,
    query: {
        token: localStorage.getItem('token'),
    },
})
