import React from 'react'
import { Video } from 'iconsax-react'
import ProjectDefault from '../../assets/images/robot/default.jpg'
import ProjectBase from '../../assets/images/robot/base-robot.png'
import ProjectJhonny from '../../assets/images/robot/jhonny-robot.png'

interface IProps {
    allocated: boolean
    name: string
    serialNumber: string
    statusCam: 'ONLINE' | 'OFFLINE'
    statusEsp32: 'ONLINE' | 'OFFLINE'

    onClick?: () => void
}

const Cards: React.FC<IProps> = ({ allocated, name, serialNumber, statusCam, statusEsp32, onClick }) => {
    const imageRobot = () => {
        if (serialNumber === 'A01' || serialNumber === 'A02') {
            return ProjectJhonny
        } else if (serialNumber === 'RB01' || serialNumber === 'RB02') {
            return ProjectBase
        } else {
            return ProjectDefault
        }
    }
    return (
        <div className="col-md-6 col-xxl-3">
            <div className="card d-block" style={{ opacity: allocated ? 0.5 : 1 }}>
                <div>
                    <img className="card-img-top" src={imageRobot()} alt="project cap" />
                    <div className="card-img-overlay">
                        {statusCam === 'ONLINE' ? <div className="badge text-bg-success p-1">Conectado</div> : <div className="badge text-bg-secondary p-1">Desconectado</div>}
                        {statusEsp32 === 'ONLINE' ? (
                            <div className="ms-1 badge text-bg-success p-1">Conectado</div>
                        ) : (
                            <div className="ms-1 badge text-bg-secondary p-1">Desconectado</div>
                        )}
                    </div>
                </div>

                <div className="card-body position-relative">
                    <h4 role="button" className="mt-0 text-title" onClick={onClick}>
                        {name}
                    </h4>
                    <p className="text-muted my-2">
                        Serial Number: <strong>{serialNumber}</strong>
                    </p>

                    <p className="mb-3">
                        <span className="text-nowrap d-flex">
                            <Video size="20" />
                            <b className="mx-1">0</b>
                            <span>Assistindo</span>
                        </span>
                    </p>
                    {/* <p className="mb-2 fw-bold">
                        Progress <span className="float-end">25%</span>
                    </p>
                    <div className="progress progress-sm">
                        <div className="progress-bar" role="progressbar" style={{ width: '25%' }}></div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default React.memo(Cards)
