import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import NoTime from '../../assets/images/no_time.svg'

interface IProps {
    show: boolean
    handleClose: () => void
    onConfirm: () => void
}

const ModalView: React.FC<IProps> = ({ show, handleClose, onConfirm }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header className="text-bg-warning border-0">
                <Modal.Title>O robô está ocupado :c </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="mb-3 d-flex justify-content-center">
                    <img src={NoTime} alt="alocado" width={200} />
                </div>
                <p className="text-center">Atualmente esse robô já está alocado para uma pessoa. Aguarde ela sair para que você possa usar</p>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row justify-content-between">
                <Button onClick={() => onConfirm()} type="button" variant="outline-secondary">
                    Visualizar
                </Button>
                <Button onClick={handleClose} type="button" variant="warning">
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalView
