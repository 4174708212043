export type ITypeClient = 'client' | 'ESPCAM' | 'ESP32'
export type ISessionState = 'LOADING' | 'AUTHENTICATED' | 'UNAUTHENTICATED'

export type IControllerType = 'KEYBOARD' | 'GAMEPAD'
export type ICameraControl = 'ANALOGIC' | 'ARROW'

export type IDirection = "up" | "down" | "left" | "right" | "stop"

export enum EFrameSize {
    '96x96' = 0,
    '160x120' = 1,
    '176x144' = 2,
    '240x176' = 3,
    '240x240' = 4,
    '320x240' = 5,
    '400x296' = 6,
    '480x320' = 7,
    '640x480' = 8,
    '800x600' = 9,
    '1024x768' = 10,
    '1280x1024' = 11,
    '1600x1200' = 12,
}

export interface ITelemetry {
    id: string
    type: ITypeClient
    battery: number
    signal: number
    signal_percent: number
    temperature: number
    latency: number
}

export interface ISessionUser {
    id: string
    name: string
    email: string
    role: 'ADMIN' | 'USER'
}
